import { useTranslation } from "react-i18next";
import "./Portfolio.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const imageLinks = [
  "https://cdnb.artstation.com/p/assets/images/images/075/267/039/large/alper-dagli-racemetothejoy.jpg?1714127895",
  "https://cdnb.artstation.com/p/assets/images/images/075/267/033/large/alper-dagli-blast.jpg?1714127887",
  "https://cdna.artstation.com/p/assets/images/images/073/573/628/large/alper-dagli-sparrow.jpg?1709972178",
  "https://cdnb.artstation.com/p/assets/images/images/073/450/129/large/alper-dagli-worthit.jpg?1709682811",
  "https://cdna.artstation.com/p/assets/images/images/073/450/124/large/alper-dagli-remi.jpg?1709682802",
  "https://cdna.artstation.com/p/assets/images/images/071/643/534/original/alper-dagli-chillancholy.gif?1705608021",
  "https://cdnb.artstation.com/p/assets/images/images/071/643/157/original/alper-dagli-glx-canvas-400.gif?1705607379",
  "https://cdna.artstation.com/p/assets/images/images/071/643/350/original/alper-dagli-slush.gif?1705607673",
  "https://cdna.artstation.com/p/assets/images/images/071/643/372/original/alper-dagli-wanderer.gif?1705607726",
  "https://cdnb.artstation.com/p/assets/images/images/071/643/399/original/alper-dagli-ready.gif?1705607798",
  "https://cdnb.artstation.com/p/assets/images/images/071/643/479/original/alper-dagli-forget2.gif?1705607951",
  "https://cdna.artstation.com/p/assets/images/images/071/643/574/original/alper-dagli-chill.gif?1705608077",
  "https://cdnb.artstation.com/p/assets/images/images/073/450/659/large/alper-dagli-the-bedroom-by-alper379-df2wkt1.jpg?1709684228",
  "https://cdnb.artstation.com/p/assets/images/images/073/450/537/large/alper-dagli-pathway-by-alper379-df0ict2.jpg?1709683819",
  "https://cdna.artstation.com/p/assets/images/images/073/450/530/large/alper-dagli-fishermen-s-evening-at-the-beach-by-alper379-df0id1j.jpg?1709683805",
  "https://cdna.artstation.com/p/assets/images/images/073/450/540/large/alper-dagli-sunflowers-on-field-by-alper379-df1c6yk.jpg?1709683826",
];

const images = imageLinks.map((el) => ({ original: el, thumbnail: el }));

const Portfolio = () => {
  const { t } = useTranslation("contents");

  return (
    <section id="portfolio">
      <h1 className="portfolio-title">{t("portfolio")}</h1>
      <div className="portfolio-links">
        <a href="https://www.artstation.com/daglia" target="blank">
          ArtStation
        </a>{" "}
        |{" "}
        <a href="https://www.deviantart.com/alper379" target="blank">
          DeviantArt
        </a>{" "}
        |{" "}
        <a href="https://github.com/daglia" target="blank">
          GitHub
        </a>
      </div>
      <div className="portfolio-content">
        <ImageGallery items={images} />
      </div>
    </section>
  );
};

export default Portfolio;
