import { useTranslation } from "react-i18next";
import "./Support.css";

const Support = () => {
  const { t } = useTranslation("contents");

  return (
    <section id="support">
      <h1 className="support-title">{t("support")}</h1>
      <div className="support-content">
        <div className="support-text">
          {t("support_desc")
            .replaceAll(" ", " \u00A0")
            .split(" ")
            .map((el, i) => (
              <span key={`wavies-${i}`}>{el}</span>
            ))}
        </div>
        <a href="https://www.buymeacoffee.com/alpervibes">
          <img
            alt="Buy Me A Coffee"
            src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=alpervibes&button_colour=FFDD00&font_colour=000000&font_family=Bree&outline_colour=000000&coffee_colour=ffffff"
          />
        </a>
      </div>
    </section>
  );
};

export default Support;
