import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Button
      onClick={() => changeLanguage(i18n.language === "en" ? "tr" : "en")}
      style={{ backgroundColor: "rgb(60,60,60)", color: "black" }}
      text={i18n.language === "en" ? "TR" : "EN"}
    />
  );
}

export default LanguageSwitcher;
