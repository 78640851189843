import Button from "../Button";
import "./Navbar.css";
import logo from "../../assets/img/logo.png";
import { Link } from "react-scroll";
import Menu from "../Menu";
import { useState } from "react";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [hidden, setHidden] = useState(true);

  const { t } = useTranslation("site");

  var menuChildren = (
    <>
      <Link
        className="navbar-menu-list-item"
        to="home"
        smooth={true}
        onClick={() => setTimeout(() => setHidden(true), 1000)}
      >
        {t("home")}
      </Link>
      <Link
        className="navbar-menu-list-item"
        to="about"
        smooth={true}
        onClick={() => setTimeout(() => setHidden(true), 1000)}
      >
        {t("about")}
      </Link>
      <Link
        className="navbar-menu-list-item"
        to="music-box"
        smooth={true}
        onClick={() => setTimeout(() => setHidden(true), 1000)}
      >
        {t("music_box")}
      </Link>
      <Link
        className="navbar-menu-list-item"
        to="portfolio"
        smooth={true}
        onClick={() => setTimeout(() => setHidden(true), 1000)}
      >
        {t("portfolio")}
      </Link>
    </>
  );

  return (
    <>
      <nav className="navbar">
        <div className="logo-container">
          <img className="logo" src={logo} alt="Alper Dağlı" />
          <div className="overlay"></div>
        </div>
        <div className="navbar-menu-horizontal">{menuChildren}</div>
        <div className="right-actions">
          <LanguageSwitcher />
          <Button
            className={"ra-wide"}
            text={"Contact Me"}
            color={"success"}
            onClick={() =>
              (window.location.href = "mailto:alperdagli@outlook.com.tr")
            }
          />
          <Button
            className={"ra-narrow"}
            text={t("menu")}
            color={"success"}
            onClick={() => setHidden(!hidden)}
          />
        </div>
      </nav>
      <Menu children={menuChildren} hidden={hidden} />
    </>
  );
};

export default Navbar;
