import { useTranslation } from "react-i18next";
import "./MusicBox.css";

const MusicBox = () => {
  const { t } = useTranslation("contents");

  return (
    <section id="music-box">
      <h1 className="music-box-title">{t("music_box")}</h1>
      <div className="music-box-content">
        <iframe
          style={{ border: 0 }}
          title="music-box"
          src="https://open.spotify.com/embed/artist/4oEqzg1XcV1gXeWLLEtJga?utm_source=generator&theme=0"
          width="100%"
          height="352"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
};

export default MusicBox;
