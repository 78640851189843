import "./Home.css";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import fullStackDevelopment from "../../assets/img/traits/full-stack-development.gif";
import musicComposing from "../../assets/img/traits/music-composing.gif";
import pixelArtist from "../../assets/img/traits/pixel-artist.gif";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation("contents");

  const specialities = [
    {
      title: t("full_stack_developer"),
      desc: t("full_stack_developer_desc"),
      img: fullStackDevelopment,
    },
    {
      title: t("pixel_artist"),
      desc: t("pixel_artist_desc"),
      img: pixelArtist,
    },
    {
      title: t("music_composer"),
      desc: t("music_composer_desc"),
      img: musicComposing,
    },
  ];

  const [speciality, setSpeciality] = useState(0);
  const [appeared, setAppeared] = useState(false);

  useEffect(() => {
    setAppeared(true);
  }, [speciality]);

  return (
    <section id="home">
      <div className="home-content" key={speciality}>
        <img
          className={["intro-img", appeared ? "appear" : null].join(" ")}
          src={specialities[speciality].img}
          alt={specialities[speciality].title}
        />
        <span className="hello">{t("hello")},</span>
        <span className="intro-text">
          {t("i_m")} <span className="intro-name">Alper</span>
        </span>
        <span className={["speciality", appeared ? "appear" : null].join(" ")}>
          {specialities[speciality].title}
          <p className="intro-para">{specialities[speciality].desc}</p>
        </span>
        <div className="buttons">
          <Button
            onClick={() =>
              setSpeciality(
                0 === speciality ? specialities.length - 1 : speciality - 1
              )
            }
            text={"<"}
            color={"success"}
          />
          <Button
            onClick={() => {
              setAppeared(false);
              setSpeciality(
                specialities.length - 1 === speciality ? 0 : speciality + 1
              );
            }}
            text={">"}
            color={"success"}
          />
        </div>
      </div>
    </section>
  );
};

export default Home;
