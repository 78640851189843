import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function SEOComponent() {
  const { t } = useTranslation("seo");

  return (
    <Helmet>
      <title>{t("title")}</title>
      <meta name="description" content={t("description")} />
      <meta name="keywords" content={t("keywords")} />
      <meta name="og:title" content={t("og_title")} />
      <meta name="og:title" content={t("og_description")} />
    </Helmet>
  );
}

export default SEOComponent;
