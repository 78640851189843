import "./About.css";
import photo from "../../assets/img/about-alper.jpg";
import cv from "../../assets/pdf/CV_AlperDagli_20240806.pdf";
import Image from "../../components/Image";
import { logEvent } from "firebase/analytics";
import { useTranslation } from "react-i18next";

const About = ({ analytics }) => {
  const { t } = useTranslation("contents");

  return (
    <section id="about">
      <h1 className="about-title">{t("about_me")}</h1>
      <div className="about-content">
        <div style={{ justifyContent: "middle" }}>
          <Image
            src={photo}
            alt="That's me"
            style={{ width: "8rem", height: "8rem" }}
          />
        </div>
        <div style={{ textAlign: "left" }}>
          <p>{t("about_me_intro_1")}</p>
          <p>
            {t("about_me_intro_2")}{" "}
            <a href={cv} onClick={() => logEvent(analytics, "cv_clicked")}>
              {t("cv")}
            </a>
          </p>
          <br />
          <p>{t("about_me_list_intro")}</p>
          <ul>
            <li>{t("about_me_list_1")}</li>
            <li>{t("about_me_list_2")}</li>
            <li>{t("about_me_list_3")}</li>
            <li>{t("about_me_list_4")}</li>
            <li>{t("about_me_list_5")}</li>
            <li>{t("about_me_list_6")}</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default About;
