import "./Menu.css";
import Button from "../Button";
import { useTranslation } from "react-i18next";

const Menu = ({ children, hidden }) => {
  const { t } = useTranslation("site");

  return (
    <div className={[`menu-container`, hidden ? "hidden" : null].join(" ")}>
      {hidden ? null : (
        <>
          {children}
          <Button
            style={{ display: "block", alignSelf: "flex-end" }}
            className={"ra-wide"}
            text={t("contact_me")}
            color={"success"}
            onClick={() =>
              (window.location.href = "mailto:alperdagli@outlook.com.tr")
            }
          />
        </>
      )}
    </div>
  );
};

export default Menu;
