import Button from "../Button";
import "./Footer.css";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <small>{`${new Date().getFullYear()} - Alper D.`}</small>
      </div>
      <div className="social-media">
        <Button
          style={{ backgroundColor: "#0077B5", color: "#ffffff" }}
          text={"LI"}
          url={"https://www.linkedin.com/in/daglia/"}
        />
        <Button
          style={{ backgroundColor: "#E1306C", color: "#ffffff" }}
          text={"IG"}
          url={"https://www.instagram.com/alpervibes/"}
        />
        <Button
          style={{ backgroundColor: "#1DB954", color: "#191414" }}
          text={"SP"}
          url={"https://open.spotify.com/intl-tr/artist/4oEqzg1XcV1gXeWLLEtJga"}
        />
        <Button
          style={{ backgroundColor: "#FF0000", color: "#282828" }}
          text={"YT"}
          url={"https://www.youtube.com/channel/UCdPTUxp0ntvDaIKI2fGzqAw"}
        />
      </div>
      <div className="right-actions">
        <Link to={"home"} smooth={true}>
          <Button
            text={"UP"}
            style={{ backgroundColor: "rgb(60,60,60)", color: "black" }}
          />
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
