import "./Image.css";

const Image = ({ src, alt, style, shine }) => (
  <>
    <div className={`image-container`} style={style}>
      <div className="image-decor corner-bottom-left"></div>
      <div className="image-decor corner-bottom-right"></div>
      <img src={src} alt={alt} />
      <div className="image-overlay"></div>
    </div>
  </>
);

export default Image;
