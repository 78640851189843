import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enSite from "./locales/en/site.json";
import enSeo from "./locales/en/seo.json";
import enContents from "./locales/en/contents.json";
import trSite from "./locales/tr/site.json";
import trSeo from "./locales/tr/seo.json";
import trContents from "./locales/tr/contents.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        site: enSite,
        seo: enSeo,
        contents: enContents,
      },
      tr: {
        site: trSite,
        seo: trSeo,
        contents: trContents,
      },
    },
    fallbackLng: "en",
    ns: ["site", "seo", "contents"],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
