import "./Button.css";

const Button = ({ text, color, onClick, className, style, url }) => {
  var buttonContainer = (
    <div
      className={`button-container ${color} ${className}`}
      style={style}
      onClick={onClick}
    >
      <div className="corner corner-top-left"></div>
      <div className="corner corner-top-right"></div>
      <div className="corner corner-bottom-left"></div>
      <div className="corner corner-bottom-right"></div>
      <div className="border border-top"></div>
      <div className="border border-bottom"></div>
      <div className="border border-left"></div>
      <div className="border border-right"></div>
      <p className="button-text">{text}</p>
    </div>
  );

  return url?.length > 0 ? (
    <a href={url} target="blank">
      {buttonContainer}
    </a>
  ) : (
    buttonContainer
  );
};

export default Button;
