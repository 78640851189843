import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Footer from "./components/Footer";
import MusicBox from "./pages/MusicBox";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Portfolio from "./pages/Portfolio";
import Support from "./pages/Support";
import SEOComponent from "./components/SEOComponent";

const firebaseConfig = {
  apiKey: "AIzaSyCZgwn7Xm4Go5deOCkJyA-Dcs95sB6YBaA",
  authDomain: "alperdagli-react.firebaseapp.com",
  projectId: "alperdagli-react",
  storageBucket: "alperdagli-react.appspot.com",
  messagingSenderId: "484163700344",
  appId: "1:484163700344:web:2366774796f572bd2d8f8d",
  measurementId: "G-S1WJS7NWD2",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <SEOComponent />
      <Navbar />
      <Home />
      <About analytics={analytics} />
      <MusicBox />
      <Portfolio />
      <Support />
      <Footer />
    </div>
  );
}

export default App;
